<template>
  <div>
    <Alert ref="alertComponentss" />
    <div class="pipedrive" v-if="popupHandle">
      <div class="pipedrive__content">
        <el-row class="pipedrive__help">
          <p class="body_01">Inserir seu token de API pessoal do Pipedrive</p>
          <el-popover
            placement="top"
            :width="240"
            trigger="click"
          >
            <template #reference>
              <dm-icon class="pipedrive__icon" HelpPink />
            </template>
            <template #default>
              <div style="padding: 20px; color: var(--dm_preto_00); padding: 12px; color: var(--dm_preto_00); ">
                <p class="body_04" style=" word-break: keep-all; text-align: start;">
                  Para encontrar seu token de API pessoal do Pipedrive, basta realizar o login em sua conta no Pipedrive e acessar Configurações &#62; Preferências pessoais &#62; API
                </p>
              </div>
            </template>

          </el-popover>

        </el-row>
        <h5>Token</h5>
        <el-input v-model="inputToken" placeholder="Digite" clearable/>
        <el-row>
          <el-button @click="upPipedrive" type="primario">Confirmar</el-button>
          <el-button @click="cancel" type="secundario">Cancelar</el-button>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex';
import { UserService } from '../services/user-service'
import Alert from '../../../components/Alert.vue'

export default {
  setup(){
    const store = useStore();
    const user = store.getters["auth/getUser"]
    let popupHandle = ref(true)
    let popuVisualization = ref(true)
    const inputToken = ref(user.data.pipedriveToken)
    const userService = new UserService(user)

    const cancel = () => {
      document.querySelector('.pipedrive').classList.add('pipeAnimation--remove')
      setTimeout(() => store.dispatch('map/changePopupPipedrive', false), 950);
    }

    onMounted(() => {
      document.querySelector('.pipedrive').classList.add('pipeAnimation--add')
      setTimeout(() => document.querySelector('.pipedrive').classList.remove('pipeAnimation--add'), 1000);
    })

    return {
      popupHandle,
      popuVisualization,
      inputToken,
      userService,
      store,
      cancel,
    }
  },
  components: {
    Alert
  },
  methods: {
    async upPipedrive() {
      /* Atuliza o token Pipedrive */
      if (!this.inputToken) return;
      await this.userService.updatePipedriveUser(this.inputToken);
      this.store.dispatch('auth/updateTokenPipedrive', this.inputToken);

      /* Chama o popup de confirmação */
       this.popupHandle = !this.popupHandle
        this.$refs.alertComponentss.showCustomAlert({
          message: 'Integração com Pipedrive realizada com sucesso',
          type: 'sucess',
          active: true,
      });
    }
  }
}
</script>

<style>
.pipedrive {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  top: 0;
  right: 0;
  width: calc(100% - 312px);
  height: 100vh;
  z-index: 2010;
  transition: .2s;
}

.pipedrive__content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  background: var(--dm_creme_02);
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.4);
}

.pipedrive__help {
  display: flex;
  gap: 20px;
}

.pipeAnimation--add {
  animation-name: pipe-add;
  animation-duration: 1s;
  animation-fill-mode: forwards
}

@keyframes pipe-add {
  to {
    opacity: 1;
  }
  from {
    opacity: 0;
  }
}

.pipeAnimation--remove {
  animation-name: pipe-remove;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

@keyframes pipe-remove {
  to {
    opacity: 0;
  }
  from {
    opacity: 1;
  }
}

</style>
