<template>
  <div v-if="isPopupVisible" class="mouse-popup" :style="{ top: mouseY + 'px', left: mouseX + 'px' }">
    <slot>{{ fullText }}</slot>
  </div>
</template>

<script>
export default {
  props: ['longerText'],
  data() {
    return {
      isPopupVisible: false,
      mouseX: 0,
      mouseY: 0,
      fullText: '',
    };
  },
  methods: {
    showPopup(event) {
      this.fullText = event.toElement.innerText
      event.toElement.innerText.length > this.longerText ?
        this.isPopupVisible = true :
        null;
      this.mouseX = event.clientX;
      this.mouseY = event.clientY;
    },
    hidePopup() {
      this.isPopupVisible = false;
    },
  },
};
</script>

<style>
.mouse-popup {
  position: fixed;
  background-color: var(--dm_cinza_05);
  padding: 10px;
  border-radius: 14px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 9999;
}
</style>