import { currencyFormatter, miFormatter, floatFormatter } from "../helpers/formatters";
import { getFeatureCollection } from "../helpers/global";
import OptionsBlue from "../../../assets/icons/blueIcons/OptionsBlue.svg";
import OptionsBlack from "../../../assets/icons/blackicons/Options.svg";
import {
  GROUP_POINTS,
  TO_CAPITALIZE,
  RENDA,
  DOMICILIOS,
  RESIDENTES,
  PIB,
  POP_CRIANCAS,
  POP_JOVENS,
  POP_ADULTOS,
  POP_IDOSOS,
  MORTES_HOMENS,
  MORTES_MULHERES,
  TOTAL_MORTES,
} from "./constants";

export const metersToPixelsAtMaxZoom = (meters, latitude, zoom) => {
  meters / (78271.484 / 2 ** zoom) / Math.cos((latitude * Math.PI) / 180);
};

export const capitalizeStrings = (inputString) => {
  if (!inputString) return;
  const words = inputString.split(" ");
  const formattedWords = [];
  for (const word of words) {
    if (!TO_CAPITALIZE.includes(word.toLowerCase()) && word[0] === word[0]?.toUpperCase()) {
      formattedWords.push(word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    } else {
      formattedWords.push(word.toLowerCase());
    }
  }
  const formattedString = formattedWords.join(" ");

  return formattedString;
};

export const makePopup = (header, data, pin = false, layer) => {
  let html = "";
  if (pin) {
    const styleB = `style="font-weight: 500;"`
    const style = `style="font-size: 16px; font-family: 'Roboto', 'sans-serif'; margin: 0 0 6px 0;"`
    let showCards = []

    const cardsElements = { // Todos os possíveis dados para mostrar no popup
      [DOMICILIOS]: `<b ${styleB}>N° de domicílios:</b> ${miFormatter(parseInt(data.properties[DOMICILIOS]))}`,
      [RESIDENTES]: `<b ${styleB}>N° de domicílios:</b> ${miFormatter(parseInt(data.properties[RESIDENTES]))}`,
      [PIB]: `<b ${styleB}>PIB ${layer}:</b> ${currencyFormatter(parseInt(data.properties[PIB] ? data.properties[PIB] : 0.0))}`,
      [POP_IDOSOS]: `<b ${styleB}>Idosos:</b> ${miFormatter(parseInt(data.properties[POP_IDOSOS] ? data.properties[POP_IDOSOS] : 0))}`,
      [POP_ADULTOS]: `<b ${styleB}>Adultos:</b> ${miFormatter(parseInt(data.properties[POP_ADULTOS] ? data.properties[POP_ADULTOS] : 0))}`,
      [POP_JOVENS]: `<b ${styleB}>Jovens:</b> ${miFormatter(parseInt(data.properties[POP_JOVENS] ? data.properties[POP_JOVENS] : 0))}`,
      [POP_CRIANCAS]: `<b ${styleB}>Crianças:</b> ${miFormatter(parseInt(data.properties[POP_CRIANCAS] ? data.properties[POP_CRIANCAS] : 0))}`,
      [MORTES_HOMENS]: `<b ${styleB}>Mortes homens:</b> ${miFormatter(parseInt(data.properties[MORTES_HOMENS] ? data.properties[MORTES_HOMENS] : 0))}`,
      [MORTES_MULHERES]: `<b ${styleB}>Mortes mulheres:</b> ${miFormatter(parseInt(data.properties[MORTES_MULHERES] ? data.properties[MORTES_MULHERES] : 0))}`,
      [TOTAL_MORTES]: `<b ${styleB}>Total mortes:</b> ${miFormatter(parseInt(data.properties[TOTAL_MORTES] ? data.properties[TOTAL_MORTES] : 0))}`,
      [TOTAL_MORTES]: `<b ${styleB}>Mortes por domicílio:</b>
        ${floatFormatter(
          parseInt(data.properties[TOTAL_MORTES] ? data.properties[TOTAL_MORTES] : 0) /
          parseInt(data.properties[DOMICILIOS] ? data.properties[DOMICILIOS] : 0) )}
      `
    }

    for(let i of data.activedDadosGerais) { // Mostra somente os dados gerais que estão ativos no asidebar
      if(cardsElements[i] != undefined) showCards += `<p ${style}>${cardsElements[i]}</p>`
    }

    const formatCurrency = layer === "Município" || layer === "Setor censitário" ?
      currencyFormatter(parseInt(data.properties[RENDA]) / parseInt(data.properties[DOMICILIOS])) :
      currencyFormatter(parseInt(data.properties[RENDA]))

    html = `
      <div style="width: 100%;">
        <h3>${data.properties.nome}</h3>
        <p style="font-size: 12px; font-family: 'Roboto', 'sans-serif'; margin: 8px 0; font-weight: 300;">${layer}</p>
        <hr>
        <h5 style="margin: 10px 0 6px 0">Dados da região</h5>
        <p ${style}><b ${styleB}>Renda mensal:</b> ${formatCurrency}</p>
        ${showCards}
      </div>
    `
  } else {
    let headerName = "Ponto Único";
    let handlePositions = "nome";
    console.log(data.properties)
    GROUP_POINTS.forEach((el) => {
      if (el.discriminator.includes(header)) {
        headerName = el.name;
        handlePositions =
          data.properties["nome fantasia"] && data.properties["nome fantasia"] !== "-"
            ? "nome fantasia"
            : handlePositions;
      }
    });
    html = `<div>
      <p
        style="
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 260px;
        margin-bottom: 5px;
        font-size: 12px;
        font-family: 'Roboto', 'sans-serif';
        font-weight: 300;"
      >${headerName}</p>
      <h3 style="margin-bottom: 10px;">${
        !data.properties[handlePositions] || data.properties[handlePositions] === "-"
          ? !data.properties.displayname ? "Não encontrado" : data.properties.displayname
          : data.properties[handlePositions]
      }</h3>
      <p
        style="
        font-size: 16px;
        font-family: 'Roboto', 'sans-serif';
        font-weight: 400;"
      >Endereço: ${
        data.properties.address ? capitalizeStrings(data.properties.address) : capitalizeStrings(data.properties.shortformattedaddress) ??
        `${capitalizeStrings(data.properties.city)} - ${capitalizeStrings(data.properties.state)}`
      }</p>
    </div>`;
  }
  return html;
};

export const makePopupLeads = (header, data) => {
  let newData = data[0]
  let html = `<div>
      <p
        style="
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 260px;
        margin-bottom: 5px;
        font-size: 12px;
        font-family: 'Roboto', 'sans-serif';
        font-weight: 300;"
      >${header}</p>
      <h3 style="margin-bottom: 10px;">${newData.properties.displayName.split('"')[3]}</h3>
      <p
        style="
        font-size: 16px;
        font-family: 'Roboto', 'sans-serif';
        font-weight: 400;"
      >Endereço: ${
        newData.properties.shortFormattedAddress
      }</p>
    </div>`;
  return html;
};

export const quantileIntervals = (
  features,
  propertyName,
  numIntervals,
  colors,
  propertyNameDivision
) => {
  // Ordenamos o conjunto de dados
  let data;
  if (propertyNameDivision === null) {
    data = features.map((feature) => feature.properties[propertyName] ?? 0);
  } else {
    data = features.map((feature) =>
      feature.properties[propertyName] && feature.properties[propertyNameDivision]
        ? feature.properties[propertyName] / feature.properties[propertyNameDivision]
        : 0
    );
  }

  const sortedData = data.slice().sort((a, b) => a - b);
  // Calculamos os quantis para cada intervalo
  const quantiles = [];
  for (let i = 0; i < numIntervals; i++) {
    const index = Math.floor((i / numIntervals) * sortedData.length);
    quantiles.push(sortedData[index]);
  }
  quantiles.push(sortedData[sortedData.length - 1]);
  quantiles.sort((a, b) => a - b);

  // Criamos os intervalos e as cores correspondentes
  const intervals = [];
  for (let i = 0; i < numIntervals; i++) {
    intervals.push([quantiles[i], quantiles[i + 1]]);
  }

  // Mapeamos cada intervalo a uma cor
  const intervalColorMap = {};
  for (let i = 0; i < numIntervals; i++) {
    const interval = intervals[i];
    if (i === 0) interval[0] = sortedData[0] * 0.9;
    if (i === numIntervals - 1) interval[1] = sortedData.at(-1) * 1.01;
    const color = colors[i];
    intervalColorMap[interval.join("-")] = color;
  }

  return intervalColorMap;
};

export const quantileIntervalsClasses = (features, propertyName, colors) => {
  // Ordenamos o conjunto de dados
  let data = features.map((feature) =>
    feature.properties[propertyName] ? feature.properties[propertyName] : 0
  );
  const sortedData = data.slice().sort((a, b) => a - b);

  // Calculamos os quantis para quartil 50%, quartil 90% e os 10% maiores
  const numIntervals = 3;
  const quantiles = [];
  quantiles.push(sortedData[0]);
  quantiles.push(sortedData[Math.floor(0.5 * sortedData.length)]);
  quantiles.push(sortedData[Math.floor(0.9 * sortedData.length)]);
  quantiles.push(sortedData[sortedData.length - 1]);

  // Criamos os intervalos e as cores correspondentes
  const intervals = [];
  for (let i = 0; i < numIntervals; i++) {
    intervals.push([quantiles[i], quantiles[i + 1]]);
  }

  // Adicione um intervalo para os 10% maiores
  // intervals.push([quantiles[numIntervals - 1], Number.POSITIVE_INFINITY]);
  // Mapeamos cada intervalo a uma cor
  const intervalColorMap = {};
  for (let i = 0; i < numIntervals; i++) {
    const interval = intervals[i];
    const color = colors[i];
    intervalColorMap[interval.join("-")] = color;
  }

  return intervalColorMap;
};

export const handleSelectedFeatures = (featureResponseId, features) => {
  const handleColor = (featureId, selected = false) => {
    let elementId = document.getElementById(featureId);
    let elementIdOptions = document.getElementById(`${featureId}_Options`);
    if (elementId === null || elementIdOptions === null) {
      return;
    } else {
      selected
        ? ((elementId.style.color = "var(--dm_azul_01)"),
          (elementIdOptions.style.backgroundImage = `url(${OptionsBlue})`))
        : ((elementId.style.color = "var(--dm_preto_00)"),
          (elementIdOptions.style.backgroundImage = `url(${OptionsBlack})`));
    }
  };
  features.forEach((feature) => {
    handleColor(feature.id, featureResponseId === feature.id);
  });
};
