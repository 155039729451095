import mapboxgl from "mapbox-gl";
import { makePopup, makePopupLeads } from "../helpers/transforms";
import axios from "axios";
import * as turf from "@turf/turf";
import {
  MUNI_SOURCE,
  POINTS_SOURCE,
  SECTOR_SOURCE,
  ID_LAYERS,
  STYLE_POINTS,
  GROUP_POINTS_COMPANY,
  GROUP_POINTS_COMPANY_2,
  CLASSES,
  LEADS_SOURCE,
  LEADS_LAYER,
} from "../helpers/constants";
import { handleSelectedFeatures } from "../helpers/transforms";
import { FeatureService } from "../services/feature-service";
import MyFeature from "../models/feature";
import { API_URL } from "../../bi/helpers/constants";

class MapService {
  constructor() {
    this._markerImage = "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png";
    this._event = null;
    this.pins = [];
    this.flagLoad = false;
    this.activeIdle = false;
    this.sideBarTableOpen = null;
    this.filter = {};
    this.firstSymbolId = 0;
    this.idPoint = { groups: [], centralized: { id: null, stats: false } };
    this.clickedPoint = null;
    this.hoveredPoint = null;
    this.url = "https://api.mapalytics.datamachina.com.br/mapalytics";
  }

  getSourcesIds() {
    const sources = this.map.getStyle().sources;
    const sourceIds = Object.keys(sources);
    return sourceIds;
  }

  getEvent() {
    return this._event;
  }

  async addNameByLngLat({ lat, lng }, token) {
    try {
      const response = await axios.get(this.url + `/properties/location/${lat},${lng}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  initMap(mapInstance, store) {
    this.map = mapInstance;
    this.store = store;
    this.map.loadImage(this._markerImage, (error, image) => {
      if (error) throw error;
      this.map.addImage("custom-marker", image);
    });

    this.map.on("click", (event) => {
      if (
        store.getters["map/getMapActualState"] === "clickRadius" ||
        store.getters["map/getMapActualState"] === "clickMarker"
      ) {
        store.dispatch("map/changeMapState", "clickMap");
        this._event = event;
      }
      var features = this.map.queryRenderedFeatures(event.point);

      // Encontre o recurso do polígono entre os recursos clicados
      var polygonFeature = features.find(function (feature) {
        return feature.layer.type === "fill";
      });

      // Verifique se um recurso de polígono foi encontrado
      if (polygonFeature) {
        // Imprima o objeto completo do recurso do polígono no console
      }
    });

    this.map.on("mouseover", () => {
      if (
        store.getters["map/getMapActualState"] === "clickRadius" ||
        store.getters["map/getMapActualState"] === "clickMarker"
      ) {
        this.map.getCanvas().style.cursor = "pointer";
      }
    });

    this.map.on("mouseleave", () => {
      this.map.getCanvas().style.cursor = "grab";
    });

    ID_LAYERS.forEach((layer) => {
      this.map.on("click", layer, (e) => {
        const { features } = e;
        if (!features.length) return;
        const layerPopUp = (layerId) => {
          switch (layerId) {
            case ID_LAYERS[0]:
              return "Estado";
            case ID_LAYERS[1]:
              return "Município";
            case ID_LAYERS[2]:
              return "Setor censitário";
            default:
              return "Hexágono";
          }
        };
        const feature = {...features[0], activedDadosGerais: this.store.getters["map/getDataList"]};
        const html = makePopup(null, feature, true, layerPopUp(layer));

        const popup = new mapboxgl.Popup({ className: "layers" })
          .setLngLat(e.lngLat)
          .setHTML(html)
          .addTo(this.map);
      });
    });

    this.map.on("load", () => {
      const layers = this.map.getStyle().layers;
      for (const layer of layers) {
        if (layer.type === "symbol") {
          this.firstSymbolId = layer.id;
          break;
        }
      }
    });

    this.map.once("style.load", () => {
      this.flagLoad = true;
      let arrGroups = this.store.getters["map/getGroupList"];
      const { idCompany } = this.store.getters["auth/getUser"].data;

      GROUP_POINTS_COMPANY_2[idCompany].forEach((companyGroup) => {
        this.map.addSource(`group-company-${companyGroup.discriminator}`, {
          type: "geojson",
          data: [],
        });
      });


      this.map.addSource(MUNI_SOURCE, {
        type: "vector",
        url: "mapbox://datamachina.0xgl4bq9",
        promoteId: "id",
      });
      this.map.addSource(SECTOR_SOURCE, {
        type: "vector",
        url: "mapbox://datamachina.85zyvgrz",
        promoteId: "id",
      });
      this.map.addSource(POINTS_SOURCE, {
        type: "vector",
        url: "mapbox://datamachina.68xt52bh",
        promoteId: "identificador_back",
      });
      this.map.addSource(LEADS_SOURCE, {
        type: "geojson",
        data: [],
      });
      this.createLeadsLayer();
      this.addGroups(arrGroups);
    });
  }

  addSource(dataSource, idSource = "my-feature-collection", type = "geojson", coropletic = false) {
    const valueExistSource = this.checkExistSource(idSource);
    if (valueExistSource) return;
    try {
      if (coropletic && this.flagLoad) {
        this.map.addSource(idSource, {
          type: type,
          data: dataSource,
        });
      } else {
        this.map.on("load", () => {
          this.map.addSource(idSource, {
            type: type,
            data: dataSource,
          });
        });
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  cursorLoading(){
    this.map.getCanvas().style.cursor = "wait";
  }

  cursorDefault(){
    this.map.getCanvas().style.cursor = "";
  }

  updateSource(dataSource, idSource = "my-feature-collection") {
    const source = this.map.getSource(idSource);
    source
      ? source.setData(dataSource)
      : this.map.addSource(idSource, {
          type: "geojson",
          data: dataSource,
        });
  }

  checkExistSource(idSource = "my-feature-collection") {
    const source = this.map.getSource(idSource);
    return source ? true : false;
  }
  removeSource(idSource) {
    this.map.removeSource(idSource);
  }

  removeLayer(idLayer) {
    if (this.map.getLayer(idLayer))
      this.map.removeLayer(idLayer);
  }

  addPolygonLayer(idSource = "my-feature-collection", layerId = "my-multipolygon-layer") {
    this.map.on("load", () => {
      if (!this.map.getLayer(layerId)) {
        this.map.addLayer({
          id: layerId,
          source: idSource,
          type: "line",
          paint: {
            "line-width": 2.5,
            "line-color": "#000000", //Borda área de estudo
          },
          filter: ["all", ["!=", ["geometry-type"], "Point"], ["==", ["get", "visibility"], true]],
        });
      }
    });
  }

  resetStates() {
    if (!this.clickedPoint) return;
    let { removeFeatureState } = this.handleStates(
      this.clickedPoint,
      "selected",
      this.clickedPoint.source.includes("company")
    );
    this.map.removeFeatureState(removeFeatureState);
  }

  async addCoropleticLayer(
    idSource,
    idLayer,
    intervalColorMap,
    propertyName,
    sourceLayer = "",
    propertyNameDivisor
  ) {
    this.map.addLayer(
      {
        id: idLayer,
        source: idSource,
        type: "fill",
        "source-layer": sourceLayer,
        paint: {
          "fill-color": [
            "step",
            propertyNameDivisor
              ? [
                  "/",
                  ["to-number", ["get", propertyName]],
                  ["to-number", ["get", propertyNameDivisor]],
                ]
              : ["to-number", ["get", propertyName]],
            ...Object.entries(intervalColorMap).flatMap(([interval, color]) => [
              color,
              Number(interval.split("-")[1]),
            ]),
            // Cor padrão caso a renda não se encaixe em nenhum intervalo.
            "black",
          ],
          "fill-opacity": 0.5,
        },
      },
      this.firstSymbolId
    );
    this.moveLayers();
  }

  async adicionarCoropleticoDeClasses(idSource, idLayer, intervalColorMap, sourceLayer = "") {
    const propertyName = CLASSES;
    this.map.addLayer(
      {
        id: idLayer,
        source: idSource,
        type: "fill",
        "source-layer": sourceLayer,
        paint: {
          "fill-color": [
            "step",
            ["to-number", ["get", propertyName]],
            ...Object.entries(intervalColorMap).flatMap(([interval, color]) => [
              color,
              Math.ceil(Number(interval.split("-")[1]) + 1),
            ]),
            // Cor padrão caso a renda não se encaixe em nenhum intervalo.
            "black",
          ],
          "fill-opacity": 0.5,
        },
      },
      this.firstSymbolId
    );
    this.moveLayers();
  }

  async addBorderLayer(idSource, idLayer = "borderLayer") {
    const checkLayer = this.map.getLayer(idLayer);
    if (checkLayer == undefined) {
      this.map.addLayer({
        id: idLayer,
        source: idSource,
        type: "line",
        paint: {
          "line-color": "#2C6BA9",
          "line-width": 2,
          /* 'line-dasharray': [3, 1] */
        },
      });
    }
  }

  moveLayers() {
    this.map.moveLayer("circle-layer");
    this.map.moveLayer("my-multipolygon-layer");
  }

  changeCoropletic(idLayer, intervalColorMap, propertyName, propertyNameDivisor) {
    this.map.setPaintProperty(idLayer, "fill-color", undefined);
    this.resetRange(idLayer);
    this.map.setPaintProperty(idLayer, "fill-color", [
      "step",
      propertyNameDivisor !== null
        ? ["/", ["to-number", ["get", propertyName]], ["to-number", ["get", propertyNameDivisor]]]
        : ["to-number", ["get", propertyName]],
      ...Object.entries(intervalColorMap).flatMap(([interval, color]) => [
        color,
        Number(interval.split("-")[1]),
      ]),
      // Cor padrão caso a renda não se encaixe em nenhum intervalo
      "white",
    ]);
  }

  resetRange(idLayer) {
    this.filter[idLayer] = [];
    this.map.setFilter(idLayer, ["all", ...this.filter[idLayer]]);
  }

  hideRange(idLayer, propertyName, minValue, maxValue, propertyNameDivisor) {
    let newFilter;
    if (propertyNameDivisor) {
      newFilter = [
        "any",
        [
          "<=",
          ["/", ["to-number", ["get", propertyName]], ["to-number", ["get", propertyNameDivisor]]],
          minValue,
        ],
        [
          ">",
          ["/", ["to-number", ["get", propertyName]], ["to-number", ["get", propertyNameDivisor]]],
          maxValue,
        ],
      ];
    } else {
      newFilter = [
        "any",
        ["<=", ["to-number", ["get", propertyName]], minValue],
        [">", ["to-number", ["get", propertyName]], maxValue],
      ];
    }

    if (!this.filter[idLayer]) {
      this.filter[idLayer] = [newFilter];
    } else {
      this.filter[idLayer].push(newFilter);
    }
    this.map.setFilter(idLayer, ["all", ...this.filter[idLayer]]);
  }

  showRange(idLayer, propertyName, minValue, maxValue, propertyNameDivisor) {
    let newFilter;
    if (propertyNameDivisor) {
      newFilter = [
        "any",
        [
          "<=",
          ["/", ["to-number", ["get", propertyName]], ["to-number", ["get", propertyNameDivisor]]],
          minValue,
        ],
        [
          ">",
          ["/", ["to-number", ["get", propertyName]], ["to-number", ["get", propertyNameDivisor]]],
          maxValue,
        ],
      ];
    } else {
      newFilter = [
        "any",
        ["<=", ["to-number", ["get", propertyName]], minValue],
        [">", ["to-number", ["get", propertyName]], maxValue],
      ];
    }
    this.filter[idLayer] = this.filter[idLayer].filter(function (arr) {
      return JSON.stringify(arr) !== JSON.stringify(newFilter);
    });
    this.map.setFilter(idLayer, ["all", ...this.filter[idLayer]]);
  }

  addMarkerLayer(idSource = "my-feature-collection") {
    const addMarkersLoad = () => {
      const source = this.map.getSource(idSource);
      const points = source._data.features.filter(
        (feature) =>
          feature.geometry.type === "Point" && feature.properties.style === STYLE_POINTS[0]
      );
      points.forEach((point) => {
        this.addPopUpInMarker(point);
      });
    };
    this.map.on("load", () => {
      addMarkersLoad();
    });
  }

  addPopUpInMarker(point) {
    const coordinates = point.geometry.coordinates;
    const html = makePopup("Ponto único", point);
    this.addMarker(coordinates, html, "coordinates", point);
  }
  addMarker(
    coordinates,
    popupHtml,
    how = "coordinates",
    markerData,
    markerColor = "var(--dm_azul_01)"
  ) {
    if (how === "event") {
      coordinates = this._event.lngLat;
    }
    let popup = new mapboxgl.Popup({ className: "point" }).setHTML(popupHtml);
    // Cria o marcador na posição do ponto
    const marker = new mapboxgl.Marker({ color: markerColor })
      .setLngLat(coordinates)
      .setPopup(popup)
      .addTo(this.map);

    let handlePopup = true;

    const hover = () => {
      marker.getPopup().addTo(this.map);
    };

    const hoverOut = () => {
      marker.getPopup().remove();
    };

    const addingEvents = () => {
      if (handlePopup) {
        marker.getElement().addEventListener("mouseover", hover);
        marker.getElement().addEventListener("mouseleave", hoverOut);
      }
    };
    const removingEvents = () => {
      marker.getElement().removeEventListener("mouseover", hover);
      marker.getElement().removeEventListener("mouseleave", hoverOut);
    };

    addingEvents();

    marker.getElement().addEventListener("click", () => {
      addingEvents();
      const getFeatureCollection = this.store.getters["map/getFeatureCollection"];
      handleSelectedFeatures(markerData.id, getFeatureCollection.features);
      const colorMarker = marker._element.childNodes[0].childNodes[2].attributes[0].value;
      let data = { ...markerData, isActive: true };
      if (colorMarker === markerColor) {
        handlePopup = false;
        this.flyto(markerData);
        this.deselectPins();
        marker._element.childNodes[0].childNodes[2].attributes[0].value = "var(--dm_rosa_00)";
        marker.getPopup().addTo(this.map);
        removingEvents();
      } else {
        marker._element.childNodes[0].childNodes[2].attributes[0].value = markerColor;
        data.isActive = false;
        setTimeout(() => {
          marker.getPopup().remove();
          handlePopup = true;
          addingEvents();
        }, 0);
      }
      this.store.dispatch("map/changeAside", { ...data, hideTabIcons: true }); // hideTabIcons esconde o <el-tab> no sidebar
      this.store.dispatch("map/changeIsMarker", true);
    });
    this.pins.push(marker);
    return marker;
  }

  deselectPins(markerColor = "var(--dm_azul_01)") {
    this.pins.forEach((markerPins) => {
      markerPins._element.childNodes[0].childNodes[2].attributes[0].value = markerColor;
    });
  }

  selectPin(coordinates, isMarker = true, markerColor = "var(--dm_rosa_00)") {
    this.deselectPins();
    this.pins.map((marker) => {
      if (
        marker._lngLat.lng === coordinates[0] &&
        marker._lngLat.lat === coordinates[1] &&
        isMarker
      ) {
        marker._element.childNodes[0].childNodes[2].attributes[0].value = markerColor;
      }
    });
  }
  deleteMarker(coordinates) {
    this.pins.map((marker) => {
      if (marker._lngLat.lng === coordinates[0] && marker._lngLat.lat === coordinates[1]) {
        this.cleanMarkers(marker);
      }
    });
  }

  handleMarkerOfLayer(feature) {
    if (!feature.properties.visibility) {
      this.pins.map((marker) => {
        if (
          marker._lngLat.lng === feature.geometry.coordinates[0] &&
          marker._lngLat.lat === feature.geometry.coordinates[1]
        ) {
          this.cleanMarkers(marker);
        }
      });
    } else {
      this.addPopUpInMarker(feature);
    }
  }

  cleanMarkers(marker) {
    marker.remove();
    this.pins = this.pins.filter((el) => el._lngLat !== marker._lngLat);
  }

  addCircleLayer(idSource = "my-feature-collection") {
    this.map.on("load", () => {
      this.map.addLayer({
        id: "circle-layer",
        type: "line",
        source: idSource,
        paint: {
          "line-color": "#000000",
          "line-width": 2,
        },
        filter: ["all", ["==", ["geometry-type"], "Polygon"], ["==", ["get", "visibility"], true]],
      });
    });
  }

  checkAtiveLayers(nameGroup) {
    const visibilityLayer = this.map.getLayer(`group-${nameGroup}`)?.visibility;
    if (!visibilityLayer) return false;
    return visibilityLayer === "visible";
  }

  getFeaturesActive(nameGroup) {
    if (this.checkAtiveLayers(nameGroup)) {
      this.store.dispatch("map/changeLegendPosition", [true, "324px"]);
      this.sideBarTableOpen = null;
      const user = this.store.getters["auth/getUser"];
      let features = [];
      features = this.map.querySourceFeatures(POINTS_SOURCE, {
        sourceLayer: nameGroup,
      });
      if (features.length <= 150) {
        this.store.dispatch(
          "map/updateGroups",
          features.map(
            (feature) =>
              new MyFeature(
                feature.id,
                user.data.idCompany,
                feature.geometry,
                feature.properties,
                nameGroup
              )
          )
        );
      } else {
        let limitedFeatures = features.slice(0, 150);
        this.store.dispatch(
          "map/updateGroups",
          limitedFeatures.map(
            (feature) =>
              new MyFeature(
                feature.id,
                user.data.idCompany,
                feature.geometry,
                feature.properties,
                nameGroup
              )
          )
        );
      }

      this.sideBarTableOpen = nameGroup;
    }
  }

  defaultSideBarOpen() {
    this.sideBarTableOpen = null;
    this.store.dispatch("map/updateGroups", false);
  }

  notVisibleGroup(nameGroup) {
    const visibilityLayer = this.map.getLayer(`group-${nameGroup}`).visibility;
    visibilityLayer === "visible"
      ? (this.map.setLayoutProperty(`group-${nameGroup}`, "visibility", "none"), this.resetStates())
      : null;
  }

  handleVisibilityLayer(nameLayer, stats) {
    const visibilityLayer = this.map.getLayer(nameLayer);
    if (!visibilityLayer) return;
    this.map.setLayoutProperty(nameLayer, "visibility", stats);
  }

  handleGroupLayer(nameGroup, idSource = POINTS_SOURCE, company = false) {
    const user = this.store.getters["auth/getUser"];
    const visibilityLayer = this.map.getLayer(`group-${nameGroup}`).visibility;

    visibilityLayer === "none"
      ? this.map.setLayoutProperty(`group-${nameGroup}`, "visibility", "visible")
      : (this.map.setLayoutProperty(`group-${nameGroup}`, "visibility", "none"),
        this.defaultSideBarOpen(),
        this.resetStates());

    if (!this.activeIdle && !company) {
      this.map.on("idle", (e) => {
        if (this.sideBarTableOpen) {
          let features = this.map.querySourceFeatures(idSource, {
            sourceLayer: this.sideBarTableOpen,
          });
          this.store.dispatch(
            "map/updateGroups",
            features.map(
              (feature) =>
                new MyFeature(
                  feature.id,
                  user.data.idCompany,
                  feature.geometry,
                  feature.properties,
                  this.sideBarTableOpen
                )
            )
          );
        }
      });
      this.activeIdle = true;
    }
  }

  createLeadsLayer() {
    let pointsLeads = {
      id: LEADS_LAYER,
      type: "circle",
      source: LEADS_SOURCE,
      layout: {
        visibility: "none",
      },
      paint: {
        "circle-radius": ["case", ["boolean", ["feature-state", "hover"], false], 7, 5],
        "circle-color": `#000`,
        "circle-stroke-color": `#606060`,
        "circle-stroke-width": ["case", ["boolean", ["feature-state", "selected"], false], 3, 2],
      },
    };
    this.map.addLayer(pointsLeads);
    const showPopUp = async (e) => {
      const { features } = e;
      if (!features.length) return;
      const html = makePopupLeads("Google", features);
      new mapboxgl.Popup({ className: "point" })
        .setLngLat(features[0].geometry.coordinates)
        .setHTML(html)
        .addTo(this.map);
    };

    this.map.on('click', LEADS_LAYER, (e) => {
  
      const leadId = e.features[0].properties.id;

      // Encontre o elemento com o ID correspondente na página
      const targetElement = document.getElementById(leadId);

      let change = document.querySelector(".change__slide");

      let tretre;
      targetElement.classList.forEach((obj) => {
        if (obj == "change__slide") tretre = true;
      });
      targetElement.classList.add("change__slide");

      if (tretre) change.classList.remove("change__slide");
      else if (change)
        change.classList.remove("change__slide"), targetElement.classList.add("change__slide");
      else if (!change) targetElement.classList.add("change__slide");

      // Verifique se o elemento existe
      if (targetElement) {
          // Navegue para o elemento correspondente
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

          // Ajuste o scroll para centralizar mais na tela
          const offset = -50; // Ajuste este valor conforme necessário
          setTimeout(() => {
            window.scrollBy(0, offset);
          },1000);
      } else {
          console.warn(`Elemento com ID ${leadId} não encontrado.`);
      }
    })

    this.map.on("mouseover", LEADS_LAYER, (e) => {
      this.map.getCanvas().style.cursor = "pointer";
      showPopUp(e);
      this.hoveredPoint = e.features[0];
      if (e.features.length === 0) return;
      let { setFeatureState } = this.handleStates(this.hoveredPoint, "hover", false);

      this.map.setFeatureState(setFeatureState, {
        hover: true,
      });
    });

    this.map.on("mouseleave", LEADS_LAYER, (e) => {
      this.map.getCanvas().style.cursor = "grab";
      let { setFeatureState } = this.handleStates(this.hoveredPoint, "hover", false);
      this.map.setFeatureState(setFeatureState, {
        hover: false,
      });
      e.target._popups[0].remove();
    });
  }

  addGroups(groupList) {
    const user = this.store.getters["auth/getUser"];
    const companiesDiscriminators = GROUP_POINTS_COMPANY_2[user.data.idCompany].map((company) => company.discriminator);
    let idSource = POINTS_SOURCE;
    let isCompany = false;

    groupList.forEach((group) => {
      const isActive = this.map.getLayer(`group-${group.discriminator}`) ? true : false;
      if (companiesDiscriminators.includes(group.discriminator)) {
        idSource = `group-company-${group.discriminator}`;
        isCompany = true;
      } else {
        idSource = POINTS_SOURCE;
        isCompany = false;
      }
      isActive
        ? null
        : this.addGroupLayer(
            group.color[1],
            idSource,
            group.discriminator,
            isCompany,
            group.borderColor[1]
          );
    });
  }

  addGroupLayer(color, idSource, nameGroup, company = false, border) {
    let points = {
      id: `group-${nameGroup}`,
      type: "circle",
      source: idSource,
      layout: {
        visibility: "none",
      },
      paint: {
        "circle-radius": [
          "case",
          ["boolean", ["feature-state", "selected"], false],
          11,
          ["boolean", ["feature-state", "hover"], false],
          7,
          5,
        ],
        "circle-color": `${color}`,
        "circle-stroke-color": `${border}`,
        "circle-stroke-width": ["case", ["boolean", ["feature-state", "selected"], false], 3, 2],
      },
    };

    if (!company) {
      // Se for false, adiciona "source-layer" no objeto
      points = { ...points, "source-layer": `${nameGroup}` };
    }

    this.map.addLayer(points); //Adiciona as layers dos pontos no mapbox
    const showPopUp = async (e) => {
      const { features } = e;
      if (!features.length) return;
      const user = this.store.getters["auth/getUser"];
      const featureService = new FeatureService(user);
      const featureById = await featureService.getFeatureById(features[0].id, user.token);
      const html = makePopup(nameGroup, featureById);
      new mapboxgl.Popup({ className: "point" })
        .setLngLat(features[0].geometry.coordinates)
        .setHTML(html)
        .addTo(this.map);
    };

    this.map.on("mouseover", `group-${nameGroup}`, async (e) => {
      this.map.getCanvas().style.cursor = "pointer";
      this.hoveredPoint = e.features[0];
      if (e.features.length === 0) return;
      let { setFeatureState } = this.handleStates(this.hoveredPoint, "hover", company);

      this.map.setFeatureState(setFeatureState, {
        hover: true,
      });
      if (!!e.target._popups.length) {
        for (const popUps of e.target._popups) {
          popUps.remove();
        }
      }
      await showPopUp(e);
    });

    this.map.on("mouseleave", `group-${nameGroup}`, (e) => {
      this.map.getCanvas().style.cursor = "grab";
      let { setFeatureState } = this.handleStates(this.hoveredPoint, "hover", company);
      this.map.setFeatureState(setFeatureState, {
        hover: false,
      });
      for (const popUps of e.target._popups) {
        popUps.remove();
      }
    });

    this.map.on("click", `group-${nameGroup}`, (e) => {
      this.resetStates();
      this.clickedPoint = e.features[0];
      if (e.features.length === 0) return;

      let { removeFeatureState, setFeatureState } = this.handleStates(
        this.clickedPoint,
        "selected",
        company
      );

      this.map.removeFeatureState(removeFeatureState);

      this.map.setFeatureState(setFeatureState, {
        selected: true,
      });
      const zoomSize = this.map.getZoom();
      if (zoomSize <= 10) {
        this.flyto(e.features[0]);
      } else {
        const centroid = turf.centroid(e.features[0]);
        this.map.flyTo({
          center: centroid.geometry.coordinates,
          zoom: 18,
          essential: true,
          duration: 1000,
        });
      }

      this.idPoint.groups.includes(nameGroup)
        ? null
        : this.map.on("zoomend", this.clickedPoint.layer.id, (event) => {
            event.features.forEach((feature) => {
              if (this.clickedPoint.id != this.idPoint.centralized.id) {
                this.idPoint.centralized.stats = false;
              }
              if (
                feature.id === this.clickedPoint.id &&
                feature.geometry.coordinates != this.map.getCenter() &&
                !this.idPoint.centralized.stats &&
                this.clickedPoint.id != this.idPoint.centralized.id
              ) {
                const centroid = turf.centroid(feature);
                this.map.flyTo({
                  center: centroid.geometry.coordinates,
                  zoom: 18,
                  essential: true,
                  duration: 1000,
                });

                this.idPoint.centralized.id = this.clickedPoint.id;
                this.idPoint.centralized.stats = true;
              }
            });
            this.idPoint.groups.push(nameGroup);
          });

      this.store.dispatch("map/changeAside", {
        ...e.features[0],
        isActive: true,
        geometry: e.features[0].geometry,
        hideTabIcons: true,
      });
    });
  }
  handleStates = (feature, state, company) => {
    let removeFeatureState = {
      source: feature.source,
      state: [state],
    };

    let setFeatureState = {
      source: feature.source,
      id: feature.id,
    };

    if (!company) {
      removeFeatureState = {
        ...removeFeatureState,
        sourceLayer: feature.sourceLayer,
      };
      setFeatureState = { ...setFeatureState, sourceLayer: feature.sourceLayer };
    }
    return { removeFeatureState, setFeatureState };
  };
  
  async flyto(feature) {
    const centroid = turf.centroid(feature);
    let zoomLevel = 0;
    if (feature.geometry.coordinates.length === 2) {
      zoomLevel = feature.properties.discriminator !== "study" ? 10 : 12;
    } else {
      if (feature.properties.discriminator === "study") {
        const user = this.store.getters["auth/getUser"];
        const featureService = new FeatureService(user);
        const featureStudy = await featureService.getFeatureById(
          feature.properties.idfeaturestudy,
          user.token
        );
        zoomLevel = featureStudy.properties.discriminator === "states" ? 6 : 10;
      } else {
        zoomLevel = feature.properties.discriminator === "states" ? 6 : 12;
      }
    }
    this.map.flyTo({
      center: centroid.geometry.coordinates,
      zoom: zoomLevel,
      essential: true,
    });
  }
  getBbox() {
    // Obtém as coordenadas da caixa delimitadora da tela
    const bounds = this.map.getBounds();

    // Obtém as coordenadas da caixa delimitadora como um array de arrays
    const bbox = [
      bounds.getWest(),
      bounds.getSouth(), // canto inferior esquerdo
      bounds.getEast(),
      bounds.getNorth(), // canto superior direito
    ];

    // Imprime as coordenadas da caixa delimitadora no console
    return bbox;
  }
}
const mapService = new MapService();

export default mapService;
