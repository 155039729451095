<template>
  <el-row class="header">
    <MapPipedrive v-if="upPipedriveHandle" />
    <el-row>
      <img class="img-header" alt="Logo da empresa com as suas iniciais DM" src="@/assets/icons/whiteicons/dm.svg" />
      <h4 style="color: var(--dm_creme_00)">Mapalytics</h4>
    </el-row>

    <el-row style="display: flex;align-items: center; gap: 10px">

      <div class="edmil">
        <div class="edmil__container">

          <!-- Mapa -->

         <!--  <router-link class="edmil__icons" to="/concorrentes" v-if="accessBI">
            <img src="../assets/icons/visao_externa.svg">
            <div class="edmil__icon--name">Visão externa</div>
          </router-link> -->

          <!-- BI -->

         <!--  <router-link class="edmil__icons" to="BI" v-if="accessBI">
            <img src="../assets/icons/visao_interna.svg">
            <div class="edmil__icon--name">Visão interna</div>
          </router-link> -->

          <!-- Mapa -->

          <router-link class="edmil__icons" to="/auth">
            <dm-icon MapWhite />
            <div class="edmil__icon--name">Mapa</div>
          </router-link>

        </div>
        <div class="column__separator" />
      </div>

      <!-- Dúvidas -->

      <el-popover placement="bottom" trigger="click" :width="max - content">
        <template #reference>
          <dm-icon class="icon" HelpWhite />
        </template>
        <el-col>
          <el-row class="optionMenu">Versão: 1.0</el-row>
          <hr />
          <el-row class="optionMenu">
            Dúvidas, sugestões ou elogios?<br>
            Mande uma mensagem para<br>
          </el-row>
          <a class="email-beta dm_azul_00_color" href="mailto:beta@datamachina.com.br">
            beta@datamachina.com.br
          </a>
        </el-col>
      </el-popover>

      <!-- Perfil -->
      <el-popover placement="bottom" trigger="click" width="none">
        <template #reference>
          <dm-icon UserWhite />
        </template>
        <el-col>
          <el-row class="tag-primaria">
            <p class="body_02 dm_preto_03--color">{{ user.data.name }}</p>
            <p class="body_04">{{ user.data.email }}</p>
          </el-row>
          <hr>
          <el-row class="optionMenu btnTerciario">
            <el-button type="terciario" @click="upPipedrive">
              <span class="body_04">Integração Pipedrive</span>
            </el-button>
          </el-row>
          <hr>
          <el-row class="optionMenu btnTerciario">
            <el-button type="terciario" @click="signOut()">
              <span class="body_04">Sair</span>
            </el-button>
          </el-row>
        </el-col>
      </el-popover>
    </el-row>
  </el-row>
</template>
<script>
import { useStore } from 'vuex';
import { ref, computed } from 'vue';
import MapPipedrive from '@/modules/map/components/MapPipedrive.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'HeaderPrincipal',
  components: {
    MapPipedrive,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const user = store.getters['auth/getUser'];
    const company = store.getters['auth/getCompany'].data;
    const accessBI = company.accessBI === 'true' ? true : false;
    const signOut = async () => {
      await store.dispatch('auth/logOut');
      router.push('/auth');
      store.dispatch('map/changePopupPipedrive', false)
    };

    let upPipedriveHandle = computed(() => store.getters['map/getPipedrive'])

    const upPipedrive = () => {
      upPipedriveHandle.value ? store.dispatch('map/changePopupPipedrive', false) : store.dispatch('map/changePopupPipedrive', true)
    }

    return { signOut, user, accessBI, upPipedriveHandle, upPipedrive, };
  },
};
</script>


<style>
.tag-primaria{
  padding: 12px;
}
.el-popover.el-popper {
  padding: 0;
}

.el-popover {
  overflow: hidden;

}

.optionMenu {
  padding: 12px;
}

.optionMenu button {
  text-decoration: none;
  height: 14px;
}

.optionMenu span {
  font-weight: 400;
}

.email-beta {
  display: block;
  padding: 12px;
  text-decoration: none;
  color: var(--dm_preto_00);
  transition: .2;
  padding: 10px 12px;
  width: 100%;
}

.email-beta:hover {
  color: var(--dm_creme_02);
}

/* Hover */
.email-beta:hover {
  background: var(--dm_azul_01);
}
</style>



<style scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  z-index: 10;
}

.edmil {
  display: flex;
  align-items: center;
}

.edmil__container {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.column__separator {
  height: 28px;
  width: 1px;
  background: var(--dm_creme_02);
}

.el-tooltip__trigger {
  cursor: pointer;
  overflow: initial;
}

.edmil__icons {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  border-radius: 50%;
  text-decoration: none;
}

.edmil__icons:hover {
  background: var(--dm_azul_01);
}

.edmil__icons img {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.edmil__icon--name {
  display: flex;
  pointer-events: none;
  align-items: center;
  opacity: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
  top: 52px;
  color: var(--dm_cinza_01);
  background: var(--dm_creme_02);
  height: 40px;
  padding: 0 10px;
  border-radius: 6px;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
}

.edmil__icons:hover .edmil__icon--name {
  width: max-content;
  opacity: 1;
}

/*  */
</style>

