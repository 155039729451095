<template>
  <div class="container_search">
    <button :class="`${activeBtn ? 'active' : 'default'} body_02`" @click="activeDropDown">
      Busca avançada
    </button>
    <div class="container_selected--list" v-if="tagsList.length > 0 && !activeBtn">
      <div class="container_selected--content" v-for="(tag, i) of tagsList" :key="i">
        <span>{{ tag }}</span>
      </div>
    </div>
    <div class="container_inputs" v-if="activeBtn">
      <el-autocomplete
        v-model="selectedItem"
        :fetch-suggestions="querySearch"
        clearable
        class="input"
        placeholder="Tipo de estabelecimento"
        @select="handleSelect"
      >
        <template #default="{ item }">
          <div>{{ item.label }}</div>
        </template>
      </el-autocomplete>
      <!-- <div class="container_selected--list" v-if="selectedFilters.type.length > 0">
        <div
          class="container_selected--content"
          v-for="(typeInList, i) of selectedFilters.type"
          :key="i"
        >
          <span>{{ typeInList.label }}</span>
          <img
            src="../../../assets/icons/blackicons/close.svg"
            alt="Close button"
            width="8"
            height="8"
            @click="removeItem(i)"
          />
        </div>
      </div> -->
      <el-select
        v-model="selectedFilters.rating"
        placeholder="Classificação"
      >
        <el-option v-for="(item, i) in DROPDOWN_RATING" :key="i" :label="item.label" :value="item">
          <span>{{ item.label }}</span>
          <span v-if="item.value != DROPDOWN_RATING[0].value"
            ><img
              v-for="(el, index) in 5"
              class="slide__star--assessment"
              :src="index < Math.floor(item.value) ? 'star__yellow.svg' : 'star__gray.svg'"
              alt="Estrela"
          /></span>
        </el-option>
      </el-select>
      <el-select class="custselect"
        v-model="selectedFilters.priceLevel"
        multiple
        collapse-tags
        collapse-tags-tooltip
        placeholder="Preço"
      >
        <el-option v-for="(item, i) in DROPDOWN_PRICE" :key="i" :label="item.label" :value="item">
          <span>{{ item.label }}</span>
        </el-option>
      </el-select>
      <div class="container_btns">
        <div class="left_btns">
          <el-button type="primario" @click="submitFilters">
            <span class="body_02">Buscar</span>
          </el-button>
          <el-button type="secundario" @click="cleanFilters">
            <span class="body_02">Limpar</span>
          </el-button>
        </div>
        <el-button type="secundario" @click="cancelFilters">
          <span class="body_02">Cancelar</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import {
  DICIONARY_TYPES,
  DROPDOWN_RATING,
  DROPDOWN_PRICE,
} from "../helpers/constants";
import { reactive } from "vue";

const activeBtn = ref(false);
const selectedItem = ref("");
let tagsList = reactive([]);
const selectedFilters = reactive({
  rating: null,
  priceLevel: [],
  type: null,
});
const emit = defineEmits(['search']);
const activeDropDown = () => {
  activeBtn.value = !activeBtn.value;
};

const querySearch = (queryString, cb) => {
  const results = queryString ? DICIONARY_TYPES.filter(createFilter(queryString)) : DICIONARY_TYPES;
  cb(results);
};

const createFilter = (queryString) => {
  return (type) => {
    return type.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
  };
};

const handleSelect = (item) => {
  selectedFilters.type = item;
  selectedItem.value = item.label;
};

// const removeItem = (index) => {
//   selectedFilters.type.splice(index, 1);
// };

const submitFilters = () => {
  concatFilters();
  emit('search', selectedItem, selectedFilters, true)
  activeBtn.value = false;
};

const concatFilters = () => {
  tagsList = [];
  Object.keys(selectedFilters).forEach((key) => {
    if (selectedFilters[key] == null) return;
    if (key == 'rating' || key == 'type') return tagsList = tagsList.concat(selectedFilters[key].label);
    tagsList = tagsList.concat(selectedFilters[key].map((item) => item.label));
  });
  console.log(tagsList)
};

const cleanFilters = () => {
  tagsList = [];
  Object.keys(selectedFilters).map((key) => {
    if (key == "rating" || key == "type") return (selectedFilters[key] = null);
    selectedFilters[key] = [];
  });
  selectedItem.value = "";
};

const cancelFilters = () => {
  cleanFilters();
  activeBtn.value = false;
  emit('cancel')
};
</script>


<style>
.custselect .el-select__tags .el-tag--info {
  background: var(--dm_azul_00);
}

.custselect .el-tag {
  height: 28px;
}
</style>
<style scoped>
.container_search {
  margin-top: 10px;
  width: 100%;
}

.default {
  color: var(--dm_azul_00);
  border-bottom: 2px solid var(--dm_azul_00);
}

.default,
.active {
  margin: 10px 0 20px 0;
}

.active {
  color: var(--dm_amarelo_00);
  border-bottom: 2px solid var(--dm_amarelo_00);
}

.container_inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.input {
  width: 100%;
}

.container_selected--list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
}

.container_selected--content {
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  height: 30px;
  border: 2px solid var(--dm_azul_01);
  border-radius: 6px;
  padding: 0 10px;
  background-color: var(--dm_creme_00);
}

.el-select-dropdown__item span {
  margin-right: 10px;
}

.container_selected--content span {
  color: var(--dm_preto_00);
  height: fit-content;
}

.container_selected--content img {
  cursor: pointer;
}

.container_btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.left_btns {
  display: flex;
}
</style>
