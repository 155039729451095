import axios from "axios";
import { API_URL } from "@/modules/bi/helpers/constants";
export class GoogleService {
  constructor(user) {
    this.user = user;
    this.url = API_URL;
  }
  async getLeadsByQuery(latLng, radius, queryString, store, filters) {
    let urlFilter = `${this.url}/companies/${this.user.data.idCompany}/features/search-text/${
      latLng[1]
    },${latLng[0]}?radius=${radius * 1000}&keyword=${queryString}`;
    if (filters) {
      const priceLevel = filters.priceLevel.filter((price) => price.value);
      filters.rating.value != "any" ? (urlFilter += `&minRating=${filters.rating.value}`) : null;
      console.log(filters.type);
      filters.type && (urlFilter += `&type=${filters.type.value}`);
      priceLevel.map((price) => {
        price.value != "any" ? (urlFilter += `&priceLevel=${price.value}`) : null;
      });
    }
    let response;
    console.log(urlFilter)
    try {
      response = await axios.get(urlFilter, {
        headers: {
          Authorization: `Bearer ${this.user.token}`,
        },
      });
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}

const googleService = new GoogleService();

export default googleService;
