import axios from 'axios';
import store from '../store';

// Axios request interceptor
axios.interceptors.request.use(async function (config) {

  // Decode token to get expiry time
  const uid = store.getters['auth/getUser'].data.id;
  // Check if token is about to expire

  if (store.getters['auth/isTokenExpired'] && uid) {
    // Token is almost expired, make a request to update it
    await axios.post(`mapalytics/users/${uid}/update-token`, { token });
  }

  return config;
}, function (error) {
  return Promise.reject(error);
});

export default axios;