<template>
  <div class="background-container" v-if="iconLoading">
    <div class="loading-container">
      <div class="loading-spinner"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapAsideBarIconLoading",
  props:{
    iconLoading: Boolean
  }
}
</script>

<style scoped>
.background-container {
  z-index: 1;
  width: 100%;
  margin-top: 20px
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-spinner {
  width: 30px;
  height: 30px;
  border: 3px dashed black;
  border-radius: 50%;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>