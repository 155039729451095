<template>
  <section style="position: relative">
    <div class="area">
      <div class="titulo">
        <img src="../assets/icons/blueicons/grafico.svg" />
        <h5>Dados Gerais</h5>
      </div>
      <div class="iconsBtn">
        <button class="area__btn--gray" v-if="radiosOptions.length == 0">
          <img src="../assets/icons/blueicons/closeGray.svg" />
        </button>

        <el-tooltip v-else content="Ativar camadas" placement="top" show-after="500">
          <button @click="showData('button')" class="eyes_btn" :disabled="load">
            <img :src="check ? close : visualizar" />
          </button>
        </el-tooltip>
        <button class="area_btn" @click="openPopUpSearch">+</button>
      </div>
    </div>
    <el-radio-group v-model="radio" class="data_options" :disabled="check" @change="handleChange">
      <el-radio
        id="radio__align"
        :label="data.label"
        :class="{ data_radio: !check }"
        v-for="data in radiosOptions"
      >
        {{ data.content }}

        <button class="global-ballon">
          <dm-icon Options />
          <div :id="data.id" class="options" />
          <div class="global-ballon__content">
            <div class="global-ballon__left">
              <div class="global-ballon__buttons">
                <dm-icon Delete @click="deletarDeDadosGerais(data.content)" />
              </div>
            </div>
          </div>
        </button>
      </el-radio>
    </el-radio-group>

    <MapMenuDataPopUp
      v-if="searchArea"
      :closePopUpSearch="closePopUpSearch"
      :dadosGeraisOptions="radios"
      :radiosOptions="radiosOptions"
    />

    <MapMenuInfo />
    <div class="background-container" v-if="load">
      <div class="loading-container">
        <div class="loading-spinner"></div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref, computed, watch } from "vue";
import MapComponentVisualization from "./MapComponentVisualization.vue";
import MapMenuDataPopUp from "./MapMenuDataPopUp.vue";
import openEyes from "../assets/icons/blueicons/visualizar.svg";
import closeEyes from "../assets/icons/blueicons/close.svg";

import MapService from "../services/map-service";
import { LayerService } from "../services/layer-service";
import { getCounties } from "../helpers/global";
import { quantileIntervals, quantileIntervalsClasses } from "../helpers/transforms";
import { mapGetters, useStore } from "vuex";
import {
  COLORS,
  MUNICIPIO,
  SECTOR_SOURCE,
  SECTOR_LAYER,
  ESTADO,
  SETOR_CENSITARIO,
  STATE_SOURCE,
  STATE_LAYER,
  MUNI_LAYER,
  MUNI_SOURCE,
  DTYPE,
  PIB,
  RENDA,
  NOT_VALUE,
  DOMICILIOS,
  RESIDENTES,
  CLASSES,
  POP_CRIANCAS,
  POP_JOVENS,
  POP_ADULTOS,
  POP_IDOSOS,
  MORTES_HOMENS,
  MORTES_MULHERES,
  TOTAL_MORTES,
  MORTES_DOMICILIOS,
} from "../helpers/constants";
import MapMenuInfo from "./MapMenuInfo.vue";

export default {
  components: {
    MapMenuDataPopUp,
    MapComponentVisualization,
    MapMenuInfo,
  },
  computed: { ...mapGetters("auth", ["getToken"]) },
  setup() {
    const store = useStore();
    const user = store.getters["auth/getUser"];
    const layerService = new LayerService(user);
    const getFeatureCollection = computed(() => store.getters['map/getFeatureCollection']);
    const getVisualization = computed(() => store.getters["map/getVisualization"]);
    const dadosGeraisSalvos = ref(getFeatureCollection.value.getMarkers());
    const listOfData = computed(() => store.getters["map/getDataList"]);
    const radio = ref(listOfData.value[0]);
    const radiosOptions = ref([]);
    const check = ref(true);
    const load = ref(false);
    const layers = ref(null);
    const layerId = ref(STATE_LAYER);
    const stateData = ref(
      layerService.getLayerByDiscriminatorAndBbox(user.data.idCompany, ESTADO, user.token)
    );
    const sourceLayer = ref("");
    const source = ref(STATE_SOURCE);

    const showData = async (wasItClicked) => {
      if (!wasItClicked && radiosOptions.value.length > 0) {
        radio.value = radiosOptions.value[0].label;
      }
      check.value = !check.value;
      if (!check.value && radiosOptions.value.length > 0) {
        load.value = true;
        layers.value = getVisualization.value === MUNICIPIO ? await getCounties() : await stateData.value;
        let propertyNameDivisor = radio.value === MORTES_DOMICILIOS ? RESIDENTES : null;
        MapService.addSource(layers.value, source.value, "geojson", true)

        let colorDefinition;
        if (getVisualization.value === SETOR_CENSITARIO) {
          colorDefinition = await layerService.getLayerQuantiles(
            user.data.idCompany,
            SETOR_CENSITARIO,
            radio.value,
            COLORS[radio.value].length,
            COLORS[radio.value],
            user.token
          );
        } else if (radio.value === CLASSES) {
          colorDefinition = quantileIntervalsClasses(
            layers.value.features,
            RENDA,
            COLORS[radio.value]
          );
        } else {
          colorDefinition = quantileIntervals(
            layers.value.features,
            radio.value !== MORTES_DOMICILIOS ? radio.value : TOTAL_MORTES,
            COLORS[radio.value].length,
            COLORS[radio.value],
            propertyNameDivisor
          );
        }
        propertyNameDivisor =
          (radio.value === RENDA && getVisualization.value !== ESTADO) ||
          radio.value === MORTES_DOMICILIOS
            ? RESIDENTES
            : null;
        store.dispatch("map/changeLegend", [
          colorDefinition,
          DTYPE[radio.value],
          radio.value,
          layerId.value,
          propertyNameDivisor,
        ]);
        let auxRetorno = radio.value;
        if (radio.value === MORTES_DOMICILIOS) {
          auxRetorno = TOTAL_MORTES;
        } else if (radio.value === CLASSES) {
          auxRetorno = RENDA;
        }
        console.log(source.value,
          layerId.value,
          colorDefinition,
          auxRetorno,
          sourceLayer.value,
          propertyNameDivisor);
        MapService.addCoropleticLayer(
          source.value,
          layerId.value,
          colorDefinition,
          auxRetorno,
          sourceLayer.value,
          propertyNameDivisor
        );
        load.value = false;
      } else {
        store.dispatch("map/changeLegend", null);
        MapService.removeLayer(layerId.value);
        MapService.removeSource(STATE_SOURCE);
        source.value = STATE_SOURCE;
        layerId.value = STATE_LAYER;
        sourceLayer.value = '';
        store.dispatch("map/changeVisualization", ESTADO);
      }
    };

    const setDataToList = (item) => {
      for (let i = 0; i < item.length; i++) {
        radiosOptions.value.push(radios.value.filter((obj) => obj.label === item[i])[0]);
      }
    };

    watch(listOfData, (params) => {
      // Adiciona os itens que foram selecionados no popup
      radiosOptions.value = [];

      setDataToList(params);
      showData();
    });

    const radios = ref([
      {
        content: "Renda mensal", // 0
        label: RENDA,
      },
      {
        content: "Nº de domicílios", // 1
        label: DOMICILIOS,
      },
      {
        content: "PIB", // 2
        label: PIB,
      },
      {
        content: "População Geral", // 1
        label: RESIDENTES,
      },
      {
        content: "Classe Social",
        label: CLASSES,
      },
      {
        content: "População criança",
        label: POP_CRIANCAS,
      },
      {
        content: "População jovens",
        label: POP_JOVENS,
      },
      {
        content: "População adultos",
        label: POP_ADULTOS,
      },
      {
        content: "População idosos",
        label: POP_IDOSOS,
      },
      {
        content: "Mortes homens",
        label: MORTES_HOMENS,
      },
      {
        content: "Mortes mulheres",
        label: MORTES_MULHERES,
      },
      {
        content: "Mortes totais",
        label: TOTAL_MORTES,
      },
      {
        content: "Mortes por domicílios",
        label: MORTES_DOMICILIOS,
      },
    ]);
    setDataToList(listOfData.value);
    return {
      getFeatureCollection,
      radio,
      dadosGeraisSalvos,
      layerService,
      radios,
      radiosOptions,
      grupoDePoints: computed(() => store.getters["map/getGroupList"]),
      store,
      PIB,
      RENDA,
      RESIDENTES,
      DOMICILIOS,
      CLASSES,
      NOT_VALUE,
      POP_CRIANCAS,
      POP_JOVENS,
      SETOR_CENSITARIO,
      POP_ADULTOS,
      POP_IDOSOS,
      MORTES_HOMENS,
      MORTES_MULHERES,
      TOTAL_MORTES,
      MORTES_DOMICILIOS,
      getVisualization,
      load,
      check,
      layers,
      layerId,
      radio,
      radiosOptions,
      stateData,
      sourceLayer,
      source,
      showData,
    };
  },
  watch: {
    getVisualization: {
      async handler(newVal, oldVal) {
        console.log(newVal, oldVal, this.check);
        if (newVal !== oldVal && !this.check) {
          this.load = true;
          MapService.removeLayer(this.layerId);
          if (newVal === MUNICIPIO || newVal === NOT_VALUE) {
            this.layers = await this.countiesData;
            this.source = MUNI_SOURCE;
            this.layerId = MUNI_LAYER;
            this.sourceLayer = "municipios";
            MapService.addBorderLayer(STATE_SOURCE);
          } else if (newVal === ESTADO) {
            this.source = STATE_SOURCE;
            this.layerId = STATE_LAYER;
            this.sourceLayer = "";
            this.layers = await this.stateData;
            MapService.removeLayer("borderLayer");
          } else if (newVal === SETOR_CENSITARIO) {
            this.source = SECTOR_SOURCE;
            this.layerId = SECTOR_LAYER;
            this.sourceLayer = "setores_censitarios";

            MapService.addBorderLayer(STATE_SOURCE);
          }

          const valores = await this.pintaMapa();
          MapService.addCoropleticLayer(
            this.source,
            this.layerId,
            valores[0],
            valores[1],
            this.sourceLayer,
            valores[2]
          );
          this.load = false;
        }
      },
    },
  },
  data() {
    const visualizar = openEyes;
    const close = closeEyes;
    const searchArea = ref(false);
    const idCompany = this.$store.getters["auth/getUser"].data.idCompany;
    const token = this.$store.getters["auth/getToken"];
    return {
      token: token,
      visualizar,
      close,
      searchArea,
      idCompany: idCompany,
      countiesData: getCounties(),
    };
  },
  methods: {
    async deletarDeDadosGerais(params) {
      this.radiosOptions.splice(
        this.radiosOptions.findIndex((obj) => obj.content == params),
        1
      );

      let index = this.radiosOptions.findIndex((obj) => obj.label === this.radio);
      if (index == -1 && this.radiosOptions.length > 0) {
        // Se o item excluido for o mesmo que estava selecionado, o primeiro item da lista será marcado
        this.radio = this.radiosOptions[0].label;
        this.showData();
      } else if (this.radiosOptions.length == 0) {
        this.showData();
      }

      let newDatas = () => {
        return this.radiosOptions.map((obj) => obj.label);
      };

      await this.layerService.updateLayer(
        this.getFeatureCollection,
        this.grupoDePoints.map((el) => el.discriminator), // Pega os grupos de pontos salvos
        newDatas()
      );
    },
    async handleChange() {
      const valores = await this.pintaMapa();
      MapService.changeCoropletic(this.layerId, valores[0], valores[1], valores[2]);
    },
    openPopUpSearch() {
      this.searchArea = !this.searchArea;
    },
    closePopUpSearch() {
      this.searchArea = false;
    },

    async pintaMapa() {
      let colorDefinition;
      let propertyNameDivisor = this.radio === this.MORTES_DOMICILIOS ? this.RESIDENTES : null;
      let aux = this.radio === this.CLASSES ? this.RENDA : this.radio;
      if (this.getVisualization === SETOR_CENSITARIO) {
        colorDefinition = await this.layerService.getLayerQuantiles(
          this.idCompany,
          SETOR_CENSITARIO,
          this.radio,
          COLORS[this.radio].length,
          COLORS[this.radio],
          this.token
        );
        if (!colorDefinition) this.store.dispatch("map/changeVisualization", NOT_VALUE);
      } else if (this.radio === this.CLASSES) {
        colorDefinition = quantileIntervalsClasses(this.layers.features, aux, COLORS[this.radio]);
      } else {
        colorDefinition = quantileIntervals(
          this.layers.features,
          this.radio !== this.MORTES_DOMICILIOS ? this.radio : this.TOTAL_MORTES,
          COLORS[this.radio].length,
          COLORS[this.radio],
          propertyNameDivisor
        );
      }
      propertyNameDivisor =
        (aux === this.RENDA && this.getVisualization !== ESTADO) || aux === this.MORTES_DOMICILIOS
          ? this.RESIDENTES
          : null;
      this.$store.dispatch("map/changeLegend", [
        colorDefinition,
        DTYPE[this.radio],
        this.radio,
        this.layerId,
        propertyNameDivisor,
      ]);
      let auxRetorno = this.radio;
      if (this.radio === this.MORTES_DOMICILIOS) {
        auxRetorno = this.TOTAL_MORTES;
      } else if (this.radio === this.CLASSES) {
        auxRetorno = this.RENDA;
      }
      return [colorDefinition, auxRetorno, propertyNameDivisor];
    },
  },
};
</script>

<style scoped>
.area {
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.area__btn--gray {
  display: flex;
  align-items: center;
}

.titulo {
  display: flex;
  gap: 10px;
  align-items: center;
}

.data_options {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0 0 20px;
  margin-bottom: 60px;
  align-items: flex-start;
}

.data_radio:hover {
  background-color: var(--dm_cinza_05);
  width: 100%;
}

.iconsBtn {
  display: flex;
  align-items: center;
  gap: 10px;
}

.eyes_btn {
  display: flex;
}

.area_btn {
  display: flex;
  color: #0567ff;
  align-items: center;
  justify-content: center;
  background: var(--dm_creme_00);
  border-radius: 50px;
  border: 2px solid #0567ff;
  width: 20px;
  height: 20px;
}

.area_btn:hover {
  background: var(--dm_azul_01);
  color: var(--dm_creme_02);
  border: var(--dm_azul_01);
}

.background-container {
  width: calc(100% - 312px);
  height: calc(100vh - 50px);
  background-color: rgba(239, 234, 230, 0.7);
  position: fixed;
  bottom: 0;
  right: 0;
}

.loading-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 45%;
  left: 48%;
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px dashed black;
  border-radius: 50%;
  animation: spin 3s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>

<style>
.data_options .is-disabled + span.el-radio__label .global-ballon {
  opacity: 0.4;
  pointer-events: none;
}

.data_options .global-ballon {
  margin-left: auto;
}

.data_options .el-radio__label {
  display: flex;
  align-items: center;
  width: 250px;
}
</style>
